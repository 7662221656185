exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dormitory-call-names-tsx": () => import("./../../../src/pages/dormitory/call_names.tsx" /* webpackChunkName: "component---src-pages-dormitory-call-names-tsx" */),
  "component---src-pages-dormitory-character-character-id-tsx": () => import("./../../../src/pages/dormitory/{Character.characterId}.tsx" /* webpackChunkName: "component---src-pages-dormitory-character-character-id-tsx" */),
  "component---src-pages-dormitory-tsx": () => import("./../../../src/pages/dormitory.tsx" /* webpackChunkName: "component---src-pages-dormitory-tsx" */),
  "component---src-pages-how-to-use-tsx": () => import("./../../../src/pages/how_to_use.tsx" /* webpackChunkName: "component---src-pages-how-to-use-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nemo-index-tsx": () => import("./../../../src/pages/nemo/index.tsx" /* webpackChunkName: "component---src-pages-nemo-index-tsx" */),
  "component---src-pages-nemo-term-tsx": () => import("./../../../src/pages/nemo/term.tsx" /* webpackChunkName: "component---src-pages-nemo-term-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-product-character-character-id-tsx": () => import("./../../../src/pages/product/{Character.characterId}.tsx" /* webpackChunkName: "component---src-pages-product-character-character-id-tsx" */),
  "component---src-pages-qa-tsx": () => import("./../../../src/pages/qa.tsx" /* webpackChunkName: "component---src-pages-qa-tsx" */),
  "component---src-pages-song-index-tsx": () => import("./../../../src/pages/song/index.tsx" /* webpackChunkName: "component---src-pages-song-index-tsx" */),
  "component---src-pages-term-tsx": () => import("./../../../src/pages/term.tsx" /* webpackChunkName: "component---src-pages-term-tsx" */),
  "component---src-pages-update-history-tsx": () => import("./../../../src/pages/update_history.tsx" /* webpackChunkName: "component---src-pages-update-history-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/newsPost.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */)
}

